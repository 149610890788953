import { Checkout } from '@/services/api/checkout';
import { LocalStoreRepository } from '@/services/local/local-store';

import { ProjectCheckoutRecoveryRepository } from './project-checkout-recovery.types';

export class LocalProjectCheckoutRecoveryRepository implements ProjectCheckoutRecoveryRepository {
  constructor(private localStore: LocalStoreRepository) {}

  getLatestCheckout(): Checkout | null {
    return this.localStore.getItem('latest-checkout');
  }

  setLatestCheckout(value: Checkout): void {
    this.localStore.setItem('latest-checkout', value);
  }

  clearLatestCheckout(): void {
    this.localStore.removeItem('latest-checkout');
  }
}
