import { LocalStoreRepository } from '@/services/local/local-store';
import { DeepPartial } from '@/utils/types';

import { ProjectCheckoutFormInput } from '../../project-checkout.form';

import { ProjectCheckoutFormRepository } from './project-checkout-form.types';

export class LocalProjectCheckoutFormRepository implements ProjectCheckoutFormRepository {
  constructor(private localStore: LocalStoreRepository) {}

  get(): DeepPartial<ProjectCheckoutFormInput> | null {
    return this.localStore.getItem('form');
  }

  set(value: DeepPartial<ProjectCheckoutFormInput>): void {
    this.localStore.setItem('form', value);
  }

  clear(): void {
    this.localStore.clear();
  }
}
