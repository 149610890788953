import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useServices } from '@/Services';
import { LocalStoreRepositoryWrapper } from '@/services/local/local-store/local-store.repository.wrapper';

import { LocalProjectCheckoutFormRepository, ProjectCheckoutFormRepository } from './services/project-checkout-form';
import {
  LocalProjectCheckoutRecoveryRepository,
  ProjectCheckoutRecoveryRepository,
} from './services/project-checkout-recovery';

export interface ProjectCheckoutServices {
  projectCheckoutFormRepository: ProjectCheckoutFormRepository;
  projectCheckoutRecoveryRepository: ProjectCheckoutRecoveryRepository;
}

const ProjectCheckoutServicesContext = createContext<ProjectCheckoutServices | null>(null);

export type ProjectCheckoutServicesProviderProps = PropsWithChildren<unknown>;

export function ProjectCheckoutServicesProvider({ children }: ProjectCheckoutServicesProviderProps) {
  const { localStorageRepository } = useServices();
  const services = useMemo<ProjectCheckoutServices>(
    () => ({
      projectCheckoutFormRepository: new LocalProjectCheckoutFormRepository(
        new LocalStoreRepositoryWrapper('@remento/checkout', localStorageRepository),
      ),
      projectCheckoutRecoveryRepository: new LocalProjectCheckoutRecoveryRepository(
        new LocalStoreRepositoryWrapper('@remento/checkout-recovery', localStorageRepository),
      ),
    }),
    [localStorageRepository],
  );
  return <ProjectCheckoutServicesContext.Provider value={services}>{children}</ProjectCheckoutServicesContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useProjectCheckoutServices(): ProjectCheckoutServices {
  const context = useContext(ProjectCheckoutServicesContext);
  if (!context) {
    throw new Error('useProjectCheckoutServices must be used inside a ProjectCheckoutServicesProvider');
  }
  return context;
}
